import apiAxios from '../../libs/apiAxios'

export default {
  namespaced: true,

  // Data
  state: {
    prescriptions: [],

    availabilityDetail: {
      date: null,
      time: null,
      slots: null,
    },

    slot: {
      id: null,
    },

    needsSlot: true,

    steps: [{
      number: '-',
      label: 'Inserimento impegnative e prestazioni',
      status: 'active',
      visibile: true,
    },
    {
      number: '-',
      label: 'Scelta disponibilità',
      status: 'disabled',
      visibile: true,
    },
    {
      number: '-',
      label: 'Prenota',
      status: 'disabled',
      visibile: true,
    }],

    bookingIdChanging: null,

    lastSaved: [],
  },

  // Get data
  getters: {
    get(state) {
      return state;
    },

    getPrescriptions(state) {
      return state.prescriptions;
    },

    getPrescriptionsPriorities(state) {
      let result = [];

      if (state.prescriptions.length !== 0) {
        state.prescriptions.forEach((element, idx) => {
          if (element.priority.value !== null && result.find((el) => el === element.priority.value) === undefined)
            result.push(element.priority.value);
        });
      }

      return result;
    },

    getAvailabilityDetail(state) {
      return state.availabilityDetail;
    },

    getSlot(state) {
      return state.slot;
    },

    getNeedsSlot(state) {
      return state.needsSlot;
    },

    getPrescriptionsWithoutDocs(state) {
      if (state.prescriptions.length === 0) return null;

      let ret = [];

      state.prescriptions.forEach((p, pIdx) => {
        let add = true;

        if (p.nr.value === null) add = false;

        let retP = {};
        retP.accessType = p.accessType.value;
        retP.cf = p.cf.value;
        retP.nr = p.nr.value;
        retP.priority = p.priority.value;

        p.exams.forEach((e, eIdx) => {
          if (e.request_documents === true)
            add = false;
        });

        if (add) {
          retP.exams = p.exams;
          ret.push(retP);
        }
      });

      return ret;
    },

    getPrescriptionsWithDocs(state) {
      if (state.prescriptions.length === 0) return null;

      let ret = [];

      state.prescriptions.forEach((p, pIdx) => {
        let add = false;

        let retP = {};
        retP.accessType = p.accessType.value;
        retP.cf = p.cf.value;
        retP.nr = p.nr.value;
        retP.priority = p.priority.value;

        p.exams.forEach((e, eIdx) => {
          if (e.request_documents === true)
            add = true;
        });

        if (add) {
          retP.exams = p.exams;
          ret.push(retP);
        }
      });

      return ret;
    },

    getBookingWithoutDocs(state, getters) {
      const booking = {};

      booking.date = state.availabilityDetail.date;
      booking.time = state.availabilityDetail.time;

      booking.prescriptions = getters.getPrescriptionsWithoutDocs;

      return booking;
    },

    getBookingWithDocs(state, getters) {
      const booking = {};
      booking.prescriptions = getters.getPrescriptionsWithDocs;
      return booking;
    },

    getSteps(state) {
      return state.steps.filter((step) => step.visibile);
    },

    getBookingIdChanging(state) {
      return state.bookingIdChanging;
    },

    getLastSaved(state) {
      return state.lastSaved;
    },
  },

  // Change data synchronously
  mutations: {
    addPrescription(state, payload) {
      let cf = {
        valid: null,
        value: null,
      };

      if (payload.cf) {
        cf = {
          valid: true,
          value: payload.cf,
        };
      }

      state.prescriptions.push({
        nr: {
          valid: null,
          value: null,
        },
        priority: {
          valid: null,
          value: null,
        },
        cf: cf,
        accessType: {
          valid: null,
          value: null,
        },
        exams: [],
      });
    },

    setPrescription(state, payload) {
      if (payload.data === 'nr') {
        state.prescriptions[payload.prescriptionNumber].nr.value = payload.value;
        state.prescriptions[payload.prescriptionNumber].nr.valid = payload.valid;
      }
      else if (payload.data === 'cf') {
        state.prescriptions[payload.prescriptionNumber].cf.value = payload.value;
        state.prescriptions[payload.prescriptionNumber].cf.valid = payload.valid;
      }
      else if (payload.data === 'priority') {
        state.prescriptions[payload.prescriptionNumber].priority.value = payload.value;
        state.prescriptions[payload.prescriptionNumber].priority.valid = payload.valid;
      }
      else if (payload.data === 'accessType') {
        state.prescriptions[payload.prescriptionNumber].accessType.value = payload.value;
        state.prescriptions[payload.prescriptionNumber].accessType.valid = payload.valid;
      }
    },

    emptyPrescription(state, payload) {
      state.prescriptions[payload.prescriptionNumber].nr.value = null;
      state.prescriptions[payload.prescriptionNumber].nr.valid = null;

      state.prescriptions[payload.prescriptionNumber].cf.value = null;
      state.prescriptions[payload.prescriptionNumber].cf.valid = null;

      state.prescriptions[payload.prescriptionNumber].priority.value = null;
      state.prescriptions[payload.prescriptionNumber].priority.valid = null;

      state.prescriptions[payload.prescriptionNumber].accessType.value = null;
      state.prescriptions[payload.prescriptionNumber].accessType.valid = null;

      state.prescriptions[payload.prescriptionNumber].exams = [];
    },

    setExams(state, payload) {
      state.prescriptions[payload.prescriptionNumber].exams = payload.exams;
    },

    set(state, payload) {
      if (payload.data == 'availabilityDetail') {
        state.availabilityDetail.date = payload.date;
        state.availabilityDetail.time = payload.time;
        state.availabilityDetail.slots = payload.slots;
      }

      else if (payload.data == 'slot') {
        state.slot.id = payload.id;
      }

      else if (payload.data == 'steps') {
        state.steps[payload.id].status = payload.value;
      }

      else if (payload.data == 'bookingIdChanging') {
        state.bookingIdChanging = payload.value;
      }
    },

    setNeedsSlot(state, payload) {
      state.needsSlot = payload;
    },

    init(state) {
      state.prescriptions = [];

      state.availabilityDetail.date = null;
      state.availabilityDetail.time = null;
      state.availabilityDetail.slots = null;

      state.slot.id = null;

      state.steps[0].status = 'active';
      state.steps[1].status = 'disabled';
      state.steps[2].status = 'disabled';

      state.bookingIdChanging = null;

      state.lastSaved = [];
    },

    hideStep(state, payload) {
      state.steps[payload.id].visibile = false;
    },

    setLastSaved(state, payload) {
      state.lastSaved.push(payload);
    },

    setState(state, payload) {
      Object.assign(state, payload);
    },
  },

  // Async methods
  actions: {
    async saveReservation(context) {
      const pr = context.getters.getPrescriptions;
      let prToSend = [];
      let addPr;
      let addEx;
      // Foreach prescriptions
      pr.forEach((itemPr, indexPr) => {
        // Transforms exams object array into only an id
        addEx = [];
        itemPr.exams.forEach((itemEx, indexEx) => {
          addEx.push(itemEx.id);
        });

        addPr = null;
        if (itemPr.nr.value !== null) {
          // Simplifies prescriptions object
          addPr = {
            accessType: itemPr.accessType.value,
            cf: itemPr.cf.value,
            nr: itemPr.nr.value,
            priority: itemPr.priority.value,
            exams: addEx,
          };
        }

        if (addPr !== null) {
          prToSend.push(addPr);
        }
      });

      const ava = context.getters.getAvailabilityDetail;
      const slot = context.getters.getSlot;
      let orgId = null;
      let agendaId = null;
      let visitMotiveId = null;
      let vmpId = null;

      if (ava.slots !== null) {
        let tmpSlot = (ava.slots.filter((s) => s.id === slot.id));
        if (tmpSlot.length > 0) {
          tmpSlot = tmpSlot[0];
          orgId = tmpSlot.orgId;
          agendaId = tmpSlot.agendaId;
          visitMotiveId = tmpSlot.visitMotiveId;
          vmpId = tmpSlot.vmpId;
        }
      }

      let reservation = {
        prescriptions: prToSend,
        date: ava.date,
        slotId: slot.id,
        orgId: orgId,
        agendaId: agendaId,
        visitMotiveId: visitMotiveId,
        vmpId: vmpId,
      };

      try {
        let response;
        // Edit existing reservation
        const bookingIdChanging = context.getters.getBookingIdChanging;
        if (bookingIdChanging !== null) {
          response = await apiAxios.put('callback-reservation/' + bookingIdChanging, reservation);
        // Insert new reservation
        } else {
          response = await apiAxios.post('callback-reservation', reservation);
        }

        // Translates backend response into frontend readable
        if (response.data !== undefined && response.data !== null) {
          const data = response.data;
          let res;
          data.forEach((item) => {
            res = {};
            try {
              res.id =  item.id;

              // item.date example: 2022-11-04T10:00:00+00:00
              res.date = '';
              res.time = '';
              if(item.date !== undefined && item.date !== '' && item.date !== null) {
                // Retrieve date
                const dateArr = item.date.split('T');
                res.date = dateArr[0];
                // Retrieve time
                const timeArr = dateArr[1].split('+');
                // Remove seconds
                const timeArrNoSec = timeArr[0].split(':');
                res.time = timeArrNoSec[0] + ':' + timeArrNoSec[1];
              }

              if (item.doctor != null) {
                res.doctor_id = item.doctor.id;
                res.doctor = item.doctor.label;
              }

              if (item.exam != null) {
                res.exam_id = item.exam.id;
                // res.exam = item.exam.label + (item.exam.cur != '' && item.exam.cur !== null ? ' (' + item.exam.cur + ')': '');
                res.exam = item.exam.label;
                res.preparation = item.exam.preparation;
              }

              if (item.insurance != null) {
                res.insurance_id = item.insurance.id;
                res.insurance = item.insurance.label;
                res.insurance_type = item.insurance.type;
              }

              if (item.practice != null) {
                res.location_id = item.practice.id;
                res.location = item.practice.label;
              }

              res.nr = item.nr;
              res.prescriptionDate = item.prescription_date;

              res.price = item.price;
              res.type = (item.type).toLowerCase();
              res.slotId = item.slot_id;
              res.diagnosticHypothesis = item.diagnostic_hypothesis;

              res.payble = item.payble;
              res.paid = item.paid;
              res.paymentMessage = item.payment_message;

              res.changeble = item.changeble;
              res.changeMessage = item.change_message;

              res.deletable = item.deletable;
              res.cancellationMessage = item.cancellation_message;

              res.orgHasDl = item.agenda.organization.has_dl;

              res.prescriptions = item.prescriptions;
              res.prescriptions.forEach((p) => {
                p.exams = p.callback_exams;
                delete p.callback_exams;
              });
              res.callback = item.callback;

              res.booking_state = item.booking_state;

              context.commit('setLastSaved', res);
            } catch(e) {
              console.log('Error translating response');
            }
          });
        }

        return { success: true };
      } catch (e) {
        return { success: false, errorMessage: e.response.data.message };
      }
    },

    saveStateToLocalStorage({ state }) {
      localStorage.setItem('recallState', JSON.stringify(state));
    },

    restoreStateFromLocalStorage({ commit }) {
      const savedState = localStorage.getItem('recallState');
      if (savedState) {
        commit('setState', JSON.parse(savedState));
        localStorage.removeItem('recallState');
      }
    },
  }
};
