<template>
    <div class="modal fade" :class="{ 'show': show, 'd-block': show }" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ text }}</p>
                    <slot></slot>
                </div>
                <div class="modal-footer" v-if="showFooter">
                    <div class="row">
                      <div class="col-6">
                        <button type="button" class="w-100 btn btn-outline-primary" data-bs-dismiss="modal" @click="dismiss"><strong>{{ dismissBtnText }}</strong></button>
                      </div>
                      <div class="col-6">
                        <button type="button" class="w-100 btn btn-primary" @click="confirm"><strong>{{ confirmBtnText }}</strong></button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" :class="{ 'show': showModalDocument, 'd-block': showModalDocument }" tabindex="-1" v-if="showModalDocument">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close" v-if="closeBtn"></button>
            </div>
            <vue-pdf-app
              :config="configureViewPdf"
              style="height: 95%"
              :pdf="modalDocument"
              v-if="showModalDocument"></vue-pdf-app>
          </div>
        </div>
      </div>
    </div>
</template>

<style lang="scss">
    .fade {
        background-color: rgb(233,240,246);
        background-color: rgba(233,240,246,0.8);
    }

    .modal-content {
        padding: 15px 15px 10px 15px;
        border-radius: 10px;

        .modal-header {
            border-bottom: unset;
        }

        .modal-footer {
            border-top: unset;
            padding-top: 20px;
            display: block;
        }
    }

    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: table;
      transition: opacity 0.3s ease;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    .modal-container {
      width: 90vw;
      max-width: 1200px;
      height: 90vh;
      max-height: 800px;
      margin: 0 auto;
      padding: 0 0 16px 0;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Helvetica, Arial, sans-serif;
    }

    @media (max-width: 768px){

      .modal-container {
        width: 100vw;
      }

    }
</style>

<script>
import VuePdfApp from "vue3-pdf-app";

export default {
  name: "DLModal",
  data () {
    return {
      configureViewPdf: {
      sidebar: {
        viewThumbnail: true,
          viewOutline: false,
          viewAttachments: false,
      },
      secondaryToolbar: {
        secondaryPresentationMode: false,
          secondaryOpenFile: false,
          secondaryPrint: true,
          secondaryDownload: true,
          secondaryViewBookmark: false,
          firstPage: true,
          lastPage: true,
          pageRotateCw: true,
          pageRotateCcw: true,
          cursorSelectTool: true,
          cursorHandTool: true,
          scrollVertical: true,
          scrollHorizontal: true,
          scrollWrapped: true,
          spreadNone: false,
          spreadOdd: false,
          spreadEven: false,
          documentProperties: false,
      },
      toolbar: {
        toolbarViewerLeft: {
          findbar: true,
            previous: true,
            next: true,
            pageNumber: true,
        },
        toolbarViewerRight: {
          presentationMode: false,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
        },
        toolbarViewerMiddle: {
          zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
        },
      },
      errorWrapper: true,
    }
    }
  },
  components: {
    VuePdfApp,
  },
  emits: ["closeModal", "dismissModal", "confirmModal"],
  props: {
    show: {
        type: Boolean,
        default: false
    },
    showModalDocument: {
        type: Boolean,
        default: false
    },
    modalDocument: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },
    text: {
        type: String,
        default: ''
    },
    dismissBtnText: {
        type: String,
        default: 'CHIUDI'
    },
    confirmBtnText: {
        type: String,
        default: 'OK'
    },
    closeBtn: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close() {
        this.$emit('closeModal');
    },
    dismiss() {
        this.$emit('dismissModal');
    },
    confirm() {
        this.$emit('confirmModal');
    }
  },
};
</script>
